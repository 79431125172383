.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.location-link {
  display: inline-block;
  background: linear-gradient(135deg, #4caf50, #81c784);
  color: #fff;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.location-link:hover {
  background: linear-gradient(135deg, #388e3c, #66bb6a);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.flex-col {
  flex-direction: column!important;
}

.flex-jsb {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 10px 0;
  border-top: 1px solid #ccc;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.react-datepicker-popper, .bottom-nav {
  z-index: 1000!important;
}

.btn-centered {
  display: block!important;
  margin: 10px auto;
  width: 100%;
  max-width: 600px;
}

.bottom-nav {
  padding: 0;
}

.bottom-nav a, .bottom-nav span {
  width: -webkit-fill-available!important;
  height: 100%;
}

.bottom-nav a svg{
  margin: auto;
  padding: 5px;
  height: 100%;
  width: max-content;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.relative span {
  display: block;
  margin: auto;
  text-align: center;
}

.p-10 {
  padding: 10px
}

.p-20 {
  padding: 20px
}

.logoCentered {
  margin: auto;
}

.btn-payment {
  background-color: #228B22;
  color: #fff;
  border-radius: 5px;
  padding: 10px;
}

.btn-payment:disabled {
  background-color: #838583;
}

.error {
  color: rgb(208, 9, 9);
}

.m-10 {
  margin: 10px;
}

.m-20 {
  margin: 20px;
}

@media screen and (max-width: 601px) {
  .hide-small {
    display: none!important;
  }
}